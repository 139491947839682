@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,500;0,600;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed&display=swap");

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: black;
}

body {
  overscroll-behavior-y: none;
  background: #f5f5f5;
  font-family: "Inter var", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  color: white;
}

a {
  color: white;
  font-weight: 500;
  text-decoration: none;
}

a:hover {
  color: white;
  font-weight: 500;
  text-decoration: underline;
}

.center {
  position: absolute;
  bottom: 50%;
  left: 50%;
  font-size: 6vw;
  font-family: "IBM Plex Sans";
  font-weight: 500;
  line-height: 1;
  color: hsla(0, 100%, 50%, 0);
  transform: translateX(-50%) translateY(50%);
  white-space: nowrap;
}
